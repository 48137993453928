<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Conventions" subtitle="Manage your organization's conventions.">

		<app-content-head-actions-item v-if="$authorised('org/conventions/create')" text="Create" icon="create" v-on:click="onCreateClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no conventions found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.slug)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.fullname" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: 'auto 100px 24px',
			endpoint: 'organisation/conventions',
			live: 'organisation/conventions'
		}

	},

	methods: {

		onEditClick: function(slug) {

			this.$router.push({
				name: 'Convention.Dashboard',
				params: {
					organisation: this.$route.params.organisation,
					convention: slug
				}
			})

		}

	}

}

</script>

<style scoped>

</style>